.status-badge {
  border-radius: 6px;
  width: fit-content;
  padding: 6px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  height: 28px;

  &__yellow {
    background-color: var(--yellow-30);
    color: var(--yellow-50);
  }

  &__orange {
    background-color: var(--orange-30);
    color: var(--orange-50);
  }

  &__red {
    background-color: var(--red-30);
    color: var(--red-50);
  }

  &__green {
    background-color: var(--green-30);
    color: var(--green-50);
  }

  &__blue {
    background-color: var(--blue-35);
    color: var(--blue-50);
  }
}
