@import "~normalize.css/normalize.css";

@import "./pico-customizations.scss";

// Set the root element
$semantic-root-element: "#root";

@import "@picocss/pico/scss/pico";

@import "./theme.scss";
@import "./mixins.scss";
@import "./atoms.scss";

// card
article {
  margin: 0;
  padding: 24px;
  background: var(--gray-00);
  border-radius: var(--border-radius);
  box-shadow: none;

  header,
  footer {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background: var(--gray-00);
  }
}

nav {
  flex-wrap: wrap;
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline.secondary,
input[type="reset"].outline {
  color: var(--gray-70);
  border-color: var(--secondary-02);

  @include hover(border) {
    border: 1px solid var(--gray-70);
  }
}

#root > main {
  @include bp-sm {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.container {
  @include bp-xl {
    max-width: 1206px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  --font-weight: 600;
}

label {
  margin: 0;
}
