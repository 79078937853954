@import "../../assets/scss/mixins.scss";

.breadcrumb {
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 4px;
  margin: 0 !important;
  margin-top: 18px !important;

  &--item {
    padding: 0;
    width: max-content;

    a {
      padding-left: 0;
      padding-right: 0;
      margin: 0;
      color: var(--secondary-02);

      @include hover(color) {
        color: var(--secondary);
      }
    }

    &__active a {
      color: var(--gray-70);
    }
  }
}
