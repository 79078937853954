.submission-result {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin: 0 auto;
}

.files {
  padding: 17px 24px 24px 24px;
  border-radius: 10px;
}
