.bullet-status {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 4px;
  align-items: center;
  flex-shrink: 0;

  &--bullet {
    height: 12px;
    width: 12px;
    border-radius: 50%;

    &__yellow {
      background-color: var(--yellow-50);
    }

    &__orange {
      background-color: var(--orange-50);
    }

    &__red {
      background-color: var(--red-50);
    }

    &__green {
      background-color: var(--green-50);
    }
  }
}
