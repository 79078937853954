@import "../../../assets/scss/mixins.scss";

.upload-item {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: var(--gray-00);
  border: 1px solid var(--gray-20);
  border-radius: 5px;
  max-width: 134px;
  height: 132px;
  cursor: pointer;
  position: relative;
  color: var(--gray-70);

  @include transition(box-shadow);
  @include hover(box-shadow) {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }

  &__error {
    border-color: var(--red-60);
    color: var(--red-60);
  }

  &--preview {
    height: 73px;
    background: var(--blue-40);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-bottom: 1px solid var(--gray-20);
    width: 100%;

    img {
      height: 100%;
      width: auto;
      object-fit: contain;
    }
  }

  &--name {
    padding: 5px;

    span {
      @include trim(3);
    }
  }

  &--remove-btn {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0;
    padding: 0;
    right: 2px;
    top: 2px;
    background-color: var(--red-60);
    opacity: 1;
    border: none;
    @include transition(opacity);

    @include hover(opacity) {
      opacity: 0.7;
    }
  }
}
