.search:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"] {
  --select-bg-color: var(--blue-30);
  --border-radius: 6px;

  border-radius: var(--border-radius);

  line-height: 1;
  padding: 8px 8px 10px 42px;
  height: 35px;
  background-color: var(--select-bg-color);
  border-color: var(--select-bg-color);
  background-position: center left 12px;
  background-size: 14px auto;
  width: 200px;

  &:focus {
    --box-shadow: 0 0 0 1px var(--select-bg-color);
  }
}
