@import "../../assets/scss/mixins.scss";

.user-sign-in {
  max-width: 630px;
  margin: auto;
  margin-top: 60px;
  border-radius: 10px;
  position: relative;

  &--from {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin: 0;
  }

  &--row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;

    @include bp-md {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--submit-btn {
    height: 55px;
    margin: 36px 0 0 0;
  }

  &--error {
    position: absolute;
    bottom: 69px;
    color: var(--red-50);
  }
}
