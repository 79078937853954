// atoms
.secondary {
  color: var(--secondary);
}

.bold {
  font-weight: 700;
}

.fw-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.fs-16 {
  font-size: 16px;
}

.fs-12 {
  font-size: 12px;
  line-height: 15px;
}

.red {
  color: var(--red-50);
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.h-fit-content {
  height: fit-content;
}

.divide {
  padding-bottom: 13px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--secondary-02);
}

.fl-col {
  display: flex;
  flex-direction: column;
}

.fl-between {
  @include fl;
  justify-content: space-between;
}

.fl-around {
  display: flex;
  justify-content: space-around;
}

.fl-gap-16 {
  @include fl;
  gap: 16px;
}

.fl-gap-8 {
  @include fl;
  gap: 8px;
}

.fl-gap-12 {
  @include fl;
  gap: 12px;
}

.fl-gap-24 {
  @include fl;
  gap: 24px;
}

.fl-inline {
  display: inline-flex;
}

// mg - margin

.mg-0 {
  margin: 0;
}

.mg-v-auto {
  margin: auto 0;
}

.mg-t-6 {
  margin-top: 6px;
}

.mg-b-6 {
  margin-bottom: 6px;
}

.mg-t-8 {
  margin-top: 8px;
}

.mg-b-8 {
  margin-bottom: 8px;
}

.mg-t-4 {
  margin-top: 4px;
}

.mg-t-12 {
  margin-top: 12px;
}

.mg-t-14 {
  margin-top: 14px;
}

.mg-b-14 {
  margin-bottom: 14px;
}

.mg-t-16 {
  margin-top: 16px;
}

.mg-b-16 {
  margin-bottom: 16px;
}

// pd - paddings
.pd-0 {
  padding: 0;
}
