@import "../../../assets/scss/mixins.scss";

.comments {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: auto;

  @include bp-xl {
    height: 500px;
  }

  &--list {
    margin: 0;
    padding: 0 16px 24px 16px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &--list-item {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}
