.document {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &--text {
    position: absolute;
    color: var(--gray-00);
    font-size: 9px;
    bottom: 7px;
    font-weight: 500;
  }
}
