.submission-genera-info {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 24px 24px 22px 24px;

  &--main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 16px;
    padding: 16px 0 24px 0;
    height: fit-content;
    border-bottom: 1px solid var(--secondary-02);
  }

  &--change-status {
    padding: 13px 0px 16px 0;
    border-bottom: 1px solid var(--secondary-02);
  }
}
