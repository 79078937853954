.submit-comment-form {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  column-gap: 8px;
  padding: 16px;
  margin-top: auto;

  &--textarea {
    border-radius: 10px;
    margin-bottom: 0;
    resize: none;
    width: 100%;
  }

  &--btn {
    border-radius: 10px;
    width: 48px;
    margin: 0;
    max-height: 35px;
    height: 35px;
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
  }
}
