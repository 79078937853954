@import "../../assets/scss/mixins.scss";

.textarea {
  margin: 0;

  @include hover(border) {
    border: 1px solid var(--gray-70);
  }

  &__with-label {
    margin-top: 4px;
  }
}

.label {
  font-weight: 500;
}
