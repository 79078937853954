@import "./pico-customizations.scss";

// flex
@mixin fl {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@mixin bp-sm {
  @media screen and (min-width: map-get($breakpoints, "sm")) {
    @content;
  }
}

@mixin bp-md {
  @media screen and (min-width: map-get($breakpoints, "md")) {
    @content;
  }
}

@mixin bp-lg {
  @media screen and (min-width: map-get($breakpoints, "lg")) {
    @content;
  }
}

@mixin bp-xl {
  @media screen and (min-width: map-get($breakpoints, "xl")) {
    @content;
  }
}

@mixin trim($numLines: null) {
  @if $numLines != null {
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } @else {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
}

@mixin transition($property: all, $duration: 0.3s, $offset: 0s) {
  transition: $property $duration $offset ease-in-out;
}

@mixin hover($property: all) {
  &:hover {
    @include transition($property);
    @content;
  }
}
