@import "../../assets/scss/mixins.scss";

.submission {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  &--main-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    @include bp-xl {
      flex-direction: row;
    }
  }

  &--info {
    order: 0;
    flex-grow: 1;

    @include bp-xl {
      order: 1;
      max-width: 630px;
      flex-basis: 630px;
    }
  }

  &--comments {
    order: 1;
    flex-grow: 1;

    @include bp-xl {
      order: 2;
      max-width: 272px;
    }
  }

  &--changelog {
    order: 2;
    flex-grow: 1;

    @include bp-xl {
      order: 0;
      max-width: 272px;
    }
  }

  &--form {
    width: 100%;
  }
}
