.submissions-card {
  &--footer {
    position: relative;
    margin-top: 16px;
  }

  &--date {
    display: flex;
    flex-direction: column;
    gap: 3px;
    position: absolute;
    right: 0;
    bottom: -8px;
    text-align: right;
  }

  &--info {
    max-width: 40%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 16px;
  }
}
