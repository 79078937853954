@import "../../assets/scss/mixins.scss";

.upload {
  height: 100%;
  display: flex;
  flex-direction: column;

  &--drop-zone {
    height: 55px;
    width: 100%;
    background: var(--gray-00);
    border: 1px dashed var(--gray-20);
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;

    gap: 8px;
    @include transition(border);

    &:hover {
      border: 1px dashed var(--gray-70);
    }

    input {
      visibility: hidden;
      position: absolute;
    }
  }

  &--list {
    margin-top: 14px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
    column-gap: 10px;
    row-gap: 16px;
    padding: 0;

    li {
      list-style: none;
      margin: 0;
    }
  }
}
