.submissions {
  &--nav {
    row-gap: 4px;
  }

  &--selects {
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
    column-gap: 16px;
  }
}
