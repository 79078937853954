@import "../../assets/scss/mixins.scss";

.modal {
  &--content {
    position: relative;
    padding: 20px 16px 16px 16px;
  }

  &--close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 16px;
    width: 16px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    right: 16px;
    top: 16px;
  }

  &--header {
    padding: 0;
    margin-bottom: 8px;
    border-bottom: none;
    text-align: center;
  }

  &--title {
    margin-bottom: 8px;
    font-weight: 600;
  }

  &--footer {
    border: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0;
    column-gap: 8px;
    margin-bottom: 0;

    &-btn {
      margin: 0;
      height: 55px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      &__secondary {
        background: var(--blue-40);
        color: var(--gray-70);
        border-color: var(--blue-40);

        @include hover("border-color") {
          border-color: var(--gray-70);
        }
      }
    }
  }
}
