.alert {
  &--title {
    margin-bottom: 7px;
  }
}

.error {
  background-color: var(--red-30);
  border-left: 2px solid var(--red-50);

  .alert--title {
    color: var(--red-50);
  }
}

.warning {
  background-color: var(--yellow-30);

  .alert--title {
    color: var(--yellow-50);
  }
}

.success {
  background-color: var(--green-30);

  .alert--msg,
  .alert--title {
    color: var(--green-50);
  }
}

.layout__horizontally {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  column-gap: 12px;
  padding: 12px 10px 10px 16px;

  .icon {
    width: 23px;
    height: 23px;
  }
}

.layout__vertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  row-gap: 12px;
  padding: 31px 35px 18px 35px;
  border: none;
  text-align: center;

  .icon {
    width: 40px;
    height: 40px;
  }

  .alert--title {
    font-size: 18px;
    line-height: 22px;
  }
}
