@import "../../assets/scss/mixins.scss";

.select {
  [role="button"] {
    background-color: var(--gray-00);
    color: var(--gray-70);
    border: 1px solid var(--secondary-02);
    position: relative;
    height: 35px;

    &::after {
      background-image: var(--icon-chevron);
      position: absolute;
      right: 12px;
    }

    @include hover(border) {
      border: 1px solid var(--gray-70);
    }
  }

  &--item {
    cursor: pointer;

    &:hover {
      background-color: var(--dropdown-hover-background-color);
    }

    &__selected {
      background-color: var(--blue-30);
    }
  }
}
