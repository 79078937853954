/* Blue Light scheme (Default) */
/* Can be forced with data-theme="light" */
[data-theme="light"],
:root:not([data-theme="dark"]) {
  --gray-00: #ffffff;
  --gray-20: #dfe1e6;
  --gray-70: #2c3d47;
  --gray-100: #000000;

  --blue-30: #e5ebf0;
  --blue-35: #dce9ff;
  --blue-40: #f4f8fb;
  --blue-50: #126afc;

  --color: var(--gray-70);
  --border-radius: 4px;
  --font-size: 14px;
  --outline-width: 1px;
  --form-element-spacing-vertical: 0.429rem;
  --nav-link-spacing-vertical: 0.429rem;
  --spacing: 0.42857rem;

  --background-color: var(--blue-40);
  --secondary: rgba(44, 61, 71, 0.5);
  --secondary-02: rgba(44, 61, 71, 0.2);

  --yellow-30: #fff9e5;
  --yellow-50: #f7bd0d;

  --orange-30: #ffeee1;
  --orange-50: #eb7e30;

  --red-30: #ffe1e1;
  --red-50: #ff3939;
  --red-60: #e30000;

  --green-30: #ddffe7;
  --green-50: #27ae60;
}
