.card-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 18px;

  &--progress {
    margin-top: 32px;
  }
}

.card-list[aria-busy="true"] {
  align-items: center;

  &::before {
    font-size: 24px;
    margin: 32px auto;
  }
}
