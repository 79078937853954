@import "../../../assets/scss/mixins.scss";

.changelog {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;

  @include bp-xl {
    height: 500px;
  }

  &--list-wrapper {
    height: 100%;
    overflow: auto;
    margin-bottom: 16px;
  }

  &--list {
    margin: 0;
    padding: 0 16px 24px 16px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &--list-item {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
}
