.header {
  height: 63px;
  align-items: center;
  background-color: var(--contrast-inverse);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0 24px;
}

.logo-wrapper {
  display: flex;
  gap: 28px;
  align-items: center;
  width: 100%;
}

.links {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  position: relative;
}

.action-links {
  margin-left: auto;
}
