.attachments-count {
  height: 28px;
  border-radius: 6px;
  border: 1px solid var(--secondary-02);
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
}
