@import "../../../assets/scss/mixins.scss";

@mixin colorStatus($color, $bgColor) {
  background-color: var($bgColor);
  border-color: var($bgColor);
  color: var($color);

  svg path {
    fill: var($color);
  }
}

.change-status {
  position: relative;

  &[open] {
    .change-status--summary {
      svg {
        rotate: 180deg;
      }
    }
  }

  .change-status--summary {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    &[aria-disabled="true"] {
      opacity: 0.6;
    }

    &::after {
      display: none;
    }

    svg {
      rotate: 0deg;
      @include transition();
    }

    &__yellow {
      @include colorStatus(--yellow-50, --yellow-30);
    }

    &__orange {
      @include colorStatus(--orange-50, --orange-30);
    }

    &__red {
      @include colorStatus(--red-50, --red-30);
    }

    &__green {
      @include colorStatus(--green-50, --green-30);
    }

    &__blue {
      @include colorStatus(--blue-50, --blue-35);
    }
  }

  .change-status--list {
    width: 185px;
    position: absolute;
    top: 35px;
    border-radius: 4px;
    left: 50%;
    padding: 13px 0 16px 0;

    &-title {
      margin-bottom: 2px;
    }

    li {
      padding: 4px 18px 4px 16px;

      div {
        cursor: pointer;
      }
    }

    li:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    li:first-of-type {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &--modal-info {
    display: flex;
    flex-direction: column;
    background: var(--blue-30);
    padding: 10px;
    font-size: 12px;
  }

  &--from-to {
    padding: 38px 0;
    display: flex;
    justify-content: center;
    column-gap: 16px;
  }
}
