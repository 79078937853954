@import "../../assets/scss/mixins.scss";

.input:not([type="checkbox"], [type="radio"]) {
  margin: 0;
  height: 48px;
  font-size: 16px;
  line-height: 1.4;

  @include hover(border) {
    border: 1px solid var(--gray-70);
  }

  &::placeholder {
    color: var(--secondary-02);
  }
}

.input__with-label {
  margin-top: 4px;
}

.label {
  font-weight: 500;
  margin-bottom: 4px;
  display: inline-flex;
}
